import {Pipe, PipeTransform, Injectable} from '@angular/core';
import {LanguageService} from "../language.service";
import {Model} from "../app.model";

export type Currency = 'EUR' | "CHF";

@Pipe({
  name: 'localized_currency'
})
@Injectable()
export class LocalizedCurrencyPipe implements PipeTransform {

  constructor(private languageService: LanguageService, private m: Model) {}

  transform(value: any, options?: {vatMarker?: boolean, currency?: string, currencyDisplay?: 'code' | 'symbol'}): any {
    const language = this.languageService.tenantLanguage;
    const currency = options?.currency
      ?? this.m.buy?.currentOrder?.tenant?.currency
      ?? this.m.account?.currentUser?.tenant?.currency
      ?? 'EUR';

    return getFmtCurrency(value, options?.vatMarker, currency, language, options?.currencyDisplay);
  }
}

@Pipe({
  name: 'localized_currency_no_symbol',
})
@Injectable()
export class LocalizedCurrencyNoSymbolPipe implements PipeTransform {

  constructor(private languageService: LanguageService) {}

  transform(value: any, options?: {vatMarker?: boolean}): any {
    const language = this.languageService.tenantLanguage;

    // We can always say currency EUR, because we want to remove it
    const fmtCurrency = getFmtCurrency(value, options?.vatMarker, 'EUR', language, 'code');

    return fmtCurrency.replace('EUR', '').trim();
  }
}

function getFmtCurrency(value: any, vatMarker?: boolean, currency?: string, language?: string, currencyDisplay?: 'code' | 'symbol') {
  vatMarker = vatMarker ?? false;
  const marker = (vatMarker === true) ? '*' : '';

  const currencyOptions = {
    style: 'currency',
    currencyDisplay: currencyDisplay ?? undefined,
    currency,
  };

  // Select the currency format based on the selected language and tenant country code,
  // e.g., de-DE, de-AT, or en-DE, en-AT
  // If the Intl.NumberFormat cannot read the code, it will always fall back to de-DE
  const fmtCurrency = (): Intl.NumberFormat => {
    try {
      language = language?.replace('_', '-') ?? 'de_DE';
      return new Intl.NumberFormat(language, currencyOptions);
    } catch (e) {
      // fallback to german format if language is not readable
      return new Intl.NumberFormat('de-DE', currencyOptions);
    }
  };

  return fmtCurrency().format(value) + marker;
}
